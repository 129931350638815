import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './App.css';
import { Pages } from '@/pages';
import { Alerts } from '@/shared/components';
import ModalCommunication from '@/shared/components/popup/modal-communication';
import ModalContact from '@/shared/components/popup/modal-contact';
import ModalFinance from '@/shared/components/popup/modal-finance';
import ModalIncomeCalculation from '@/shared/components/popup/modal-income-calculation';
import ModalLastContact from '@/shared/components/popup/modal-lastContact';
import { ModalLotteryTickets } from '@/shared/components/popup/modal-lottery-tickets';
import { ModalPromocode } from '@/shared/components/popup/modal-promocode';
import { SidebarProvider } from '@/shared/hooks';
import { router } from '@/shared/router';
import { ThemeProvider, useTheme } from '@/shared/theme';
import { RouterProvider } from 'atomic-router-react';
import { SkeletonTheme } from 'react-loading-skeleton';
function SkeletonThemeProvider(_a) {
    var children = _a.children;
    // Hooks
    var theme = useTheme().theme;
    return (_jsx(SkeletonTheme, { baseColor: theme === 'light' ? '#ebebeb' : '#1B254B', highlightColor: theme === 'light' ? '#f5f5f5' : '#262F53', children: children }));
}
export function App() {
    return (_jsx(ThemeProvider, { children: _jsx(SidebarProvider, { children: _jsxs(SkeletonThemeProvider, { children: [_jsx(RouterProvider, { router: router, children: _jsx(Pages, {}) }), _jsx(Alerts, {}), _jsx(ModalFinance, {}), _jsx(ModalLastContact, {}), _jsx(ModalContact, {}), _jsx(ModalIncomeCalculation, {}), _jsx(ModalPromocode, {}), _jsx(ModalLotteryTickets, {}), _jsx(ModalCommunication, {})] }) }) }));
}
