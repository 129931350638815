import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var AuthButton = function (_a) {
    var className = _a.className, text = _a.text, disabled = _a.disabled, onClick = _a.onClick;
    // Variables
    var ClassName = classNames('auth-button', { 'auth-button--disabled': disabled }, className);
    return (_jsx("button", { type: "submit", className: ClassName, onClick: onClick, children: text }));
};
export var MemoAuthButton = memo(AuthButton);
