import { jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classNames from 'classnames';
import { useUnit } from "effector-react/compat";
import './style.scss';
import { $date } from "@/shared/effector";
export var Footer = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('auth-layout-footer', className);
    var date = useUnit($date);
    return _jsxs("div", { className: ClassName, children: ["\u00A9 ", date, " PRM4ALL. All Rights Reserved."] });
};
export var MemoFooter = memo(Footer);
