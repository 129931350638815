import { jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { $$session } from '@/shared/effector';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo } from 'react';
export var ActivityPrm = function (_a) {
    var _b, _c, _d;
    var className = _a.className;
    // Variables
    var ClassName = classNames('activity-prm', className);
    var profile = useUnit([$$session.$profile])[0];
    return (_jsxs("div", { className: ClassName, children: ["\u0410\u043A\u0442\u0438\u0432\u043D\u043E\u0441\u0442\u044C ", (_b = profile === null || profile === void 0 ? void 0 : profile.activity) === null || _b === void 0 ? void 0 : _b.name, ": ", ((_c = profile === null || profile === void 0 ? void 0 : profile.activity) === null || _c === void 0 ? void 0 : _c.days) ? (_d = profile === null || profile === void 0 ? void 0 : profile.activity) === null || _d === void 0 ? void 0 : _d.days : 0, " \u0434\u043D\u0435\u0439"] }));
};
export var MemoActivityPrm = memo(ActivityPrm);
