import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-empty-character-class
var parseValue = function (value) {
    var trimed = value === null || value === void 0 ? void 0 : value.trim();
    var noSpace = trimed === null || trimed === void 0 ? void 0 : trimed.replaceAll(' ', '');
    var onlyValidSymbols = noSpace === null || noSpace === void 0 ? void 0 : noSpace.replaceAll(/[^\d]/gi, '');
    var replace7to8 = onlyValidSymbols === null || onlyValidSymbols === void 0 ? void 0 : onlyValidSymbols.replaceAll(/^7/gi, '8');
    var startWith8 = (replace7to8 === null || replace7to8 === void 0 ? void 0 : replace7to8.match(/^(8)/gi)) || !(replace7to8 === null || replace7to8 === void 0 ? void 0 : replace7to8.length) ? replace7to8 : '8' + replace7to8;
    return startWith8;
};
var buildMask = function (value) {
    var s = value.split('');
    var builded = "+7 (".concat(s[1] || 'X').concat(s[2] || 'X').concat(s[3] || 'X', ") ").concat(s[4] || 'X').concat(s[5] || 'X').concat(s[6] || 'X', " ").concat(s[7] || 'X').concat(s[8] || 'X', " ").concat(s[9] || 'X').concat(s[10] || 'X');
    var firstXIndex = builded.split('').findIndex(function (sym) { return sym === 'X'; });
    return firstXIndex === -1 ? builded : builded.slice(0, firstXIndex);
};
export var useTelephoneMask = function (value, onChange) {
    if (onChange === void 0) { onChange = null; }
    // State
    var _a = useState(parseValue(value)), validValue = _a[0], setValidValue = _a[1];
    var _b = useState(buildMask(parseValue(value))), maskedValue = _b[0], setMaskedValue = _b[1];
    // Handlers
    var handleChange = useCallback(function (value) {
        var parsed = parseValue(value);
        setValidValue(parsed);
        setMaskedValue(buildMask(parsed));
        onChange === null || onChange === void 0 ? void 0 : onChange(parsed);
    }, [onChange]);
    var handleKeyDown = useCallback(function (event) {
        if (event.code === 'Backspace') {
            event.stopPropagation();
            event.preventDefault();
            var parsed = validValue;
            var deleted = parsed.length === 0 ? parsed : parsed.slice(0, parsed.length - 1);
            setValidValue(deleted);
            setMaskedValue(buildMask(deleted));
            onChange === null || onChange === void 0 ? void 0 : onChange(deleted);
        }
    }, [onChange, validValue]);
    // Effects
    useEffect(function () {
        if (!onChange) {
            return;
        }
        var parsed = parseValue(value);
        if (parsed === validValue) {
            return;
        }
        setValidValue(parseValue(parsed));
        setMaskedValue(buildMask(parsed));
        onChange(parsed);
    }, [onChange, validValue]);
    return { validValue: validValue, maskedValue: maskedValue, handleChange: handleChange, handleKeyDown: handleKeyDown };
};
// Инициализация
// Значения отфармотированы
// Вернули валидное значение и значение маски
// Значение изменено
// Парсим маску
// Сохраняем валидное значение
// Возвращаем новое валидное значение и значение маски
