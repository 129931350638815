var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { Button } from '@/shared/components';
import { $$globalStrategyStatusColor } from '@/shared/effector';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import Skeleton from 'react-loading-skeleton';
export var SimpleColorPicker = function (_a) {
    var className = _a.className, colors = _a.colors, pending = _a.pending, value = _a.value, onChange = _a.onChange;
    // Variables
    var ClassName = classNames('simple-color-picker', className);
    var _b = useState(false), showColorPicker = _b[0], setShowColorPicker = _b[1];
    var _c = useColor('cyan'), color = _c[0], setColor = _c[1];
    var handleAddColor = function () {
        setShowColorPicker(!showColorPicker);
    };
    var handleColorConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            $$globalStrategyStatusColor.postColor.submited({ color_HEX: color.hex });
            setShowColorPicker(false);
            return [2 /*return*/];
        });
    }); };
    // Render
    var renderItems = function () {
        if (pending) {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(function (item) { return (_jsx(Skeleton, { containerClassName: classNames('simple-color-picker__item', 'simple-color-picker__item--skeleton') }, item)); });
        }
        return colors.map(function (_a) {
            var innerValue = _a.value, color = _a.color;
            return (_jsx("div", { className: classNames('simple-color-picker__item', value === innerValue && 'simple-color-picker__item--active'), style: { backgroundColor: color }, onClick: function () { return onChange(innerValue); } }, innerValue));
        });
    };
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "simple-color-picker__title", children: "\u0426\u0432\u0435\u0442\u0430" }), _jsxs("div", { className: "simple-color-picker__items", children: [renderItems(), _jsx("button", { className: "simple-color-picker__button", onClick: handleAddColor, children: "+" })] }), showColorPicker && (_jsxs("div", { className: "simple-color-picker__color-picker", children: [_jsx(ColorPicker, { color: color, onChange: setColor }), _jsx(Button, { variant: 'primary', onClick: handleColorConfirm, children: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C" })] }))] }));
};
export var MemoSimpleColorPicker = memo(SimpleColorPicker);
