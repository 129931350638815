import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Блок Коммуникация - страница Работа с конатктом
import { MemoActiveStatus, MemoActiveStatusForm } from './components';
import './style.scss';
import { PageLayout } from '@/shared/components';
import classNames from 'classnames';
import { memo } from 'react';
export var ContactCommunication = function (_a) {
    var className = _a.className, contact = _a.contact;
    // Variables
    var ClassName = classNames('contact-communication', className);
    return (_jsxs(PageLayout.Cloud, { className: ClassName, header: { title: 'Коммуникация' }, children: [_jsx(MemoActiveStatus, {}), _jsx(MemoActiveStatusForm, { contact: contact })] }));
};
export var MemoContactCommunication = memo(ContactCommunication);
