import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Blob } from '../blob/ui';
import { Button } from '../buttons';
import styles from './modal-communication.module.scss';
import './styles.scss';
import { $$activeContact } from '@/entities/contact/model';
import { ModalCloseIcon } from '@/shared/assets';
import { Form } from '@/shared/components';
import { $$communicationType } from '@/shared/effector';
import { $isModalOpen, closeModal, openModal } from '@/shared/effector/communication/modal-status';
import { useHideHtmlTags } from '@/shared/hooks/use-hide-htmlTags';
import { $$contactCommunication } from '@/widgets/contact/contact-communication/model';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
var ModalCommunication = function () {
    var _a = useState(false), copy = _a[0], setCopy = _a[1];
    var _b = useUnit([$isModalOpen, openModal, closeModal]), isOpenModal = _b[0], open = _b[1], close = _b[2];
    var step = useUnit([$$contactCommunication.info.$nextStep])[0];
    // console.log("некст step", step)
    var contact = useUnit($$activeContact.$item);
    // console.log("contact в модал коммуникации", contact)
    var _c = useState(1), part = _c[0], setPart = _c[1];
    // const [part, setPart] = useState(step?.comment ? 1 : 2)
    var _d = useUnit([
        $$contactCommunication.form.$communicationTypeId,
        $$contactCommunication.form.communicationTypeIdChanged,
    ]), communicationTypeId = _d[0], communicationTypeIdChanged = _d[1];
    var communicationTypes = useUnit([$$communicationType.getAll.$items])[0];
    var handleTypeClick = function (type) {
        communicationTypeIdChanged(type.communication_type_id);
        // const telegramUrl = `tg://t.me/${contact?.phone}?text=${message}`;
        var url = type.name === 'Telegram'
            ? "tg://t.me/".concat((contact === null || contact === void 0 ? void 0 : contact.telegram_ID) ? contact === null || contact === void 0 ? void 0 : contact.telegram_ID : contact === null || contact === void 0 ? void 0 : contact.phone, "?text=").concat(step === null || step === void 0 ? void 0 : step.comment)
            : type.name === 'WhatsApp'
                ? "https://wa.me/".concat(contact === null || contact === void 0 ? void 0 : contact.phone, "?text=").concat(step === null || step === void 0 ? void 0 : step.comment)
                : null;
        if (type.name === 'Telegram' || type.name === 'WhatsApp')
            window.open(url, '_blank');
        setPart(2);
    };
    // const [resultsAll] = useUnit([$$activeStrategy.$stepResults])
    var results = useUnit([$$contactCommunication.info.$nextStepResults])[0];
    var _e = useUnit([
        $$contactCommunication.form.$stepResultId,
        $$contactCommunication.form.stepResultIdChanged,
    ]), resultId = _e[0], resultIdChanged = _e[1];
    var _f = useUnit([
        $$contactCommunication.form.$comment,
        $$contactCommunication.form.commentChanged,
    ]), comment = _f[0], commentChanged = _f[1];
    var canSubmit = useUnit([$$contactCommunication.form.$canSubmit])[0];
    var _g = useUnit([
        $$contactCommunication.submited,
        $$contactCommunication.form.reseted,
    ]), submit = _g[0], reseted = _g[1];
    var handleResultClick = function (result) {
        console.log('2323232323', result.custom_strategy_step_result_id);
        resultIdChanged(result.custom_strategy_step_result_id);
        submit();
        reseted();
        setPart(1);
        close();
    };
    var handleTelegramWhatsapp = function (type) {
        var url = type === 'Telegram'
            ? "https://t.me/".concat((contact === null || contact === void 0 ? void 0 : contact.telegram_ID) ? contact === null || contact === void 0 ? void 0 : contact.telegram_ID : contact === null || contact === void 0 ? void 0 : contact.phone, "?text=").concat((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует')
            : type === 'WhatsApp'
                ? "https://wa.me/".concat(contact === null || contact === void 0 ? void 0 : contact.phone, "?text=").concat((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует')
                : null;
        if (type === 'Telegram' || type === 'WhatsApp')
            window.open(url, '_blank');
    };
    var handleCopy = function () {
        // navigator.clipboard.writeText('текст скрипта')
        navigator.clipboard.writeText((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует');
        setCopy(true);
    };
    // useEffect(() => {
    //   if (step?.comment) setPart(1)
    //   if (!step?.comment) setPart(2)
    // }, [step])
    useEffect(function () {
        if (isOpenModal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'initial';
    }, [isOpenModal]);
    useEffect(function () {
        if (copy) {
            setTimeout(function () {
                setCopy(false);
            }, 2000);
        }
    }, [copy]);
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: function () { return close(); }, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: function () { return close(); }, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), part === 1 && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.script_container, children: [_jsx("div", { className: "text", children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435" }), _jsx("p", { className: "text", children: useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) || 'Описание шага отсутствует' })] }), _jsx("p", { className: "title ".concat(styles.title_communication), children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u043F\u043E\u0441\u043E\u0431 \u0441\u0432\u044F\u0437\u0438" }), _jsxs("div", { className: "button-contacts-wrapper ".concat(styles.button_contacts_wrapper_communication), children: [_jsx(Button, { circle: true, variant: "primary", onClick: function () { return handleTelegramWhatsapp('Telegram'); }, className: "long-button", children: "Telegram" }), _jsx(Button, { circle: true, variant: "primary", onClick: function () { return handleTelegramWhatsapp('WhatsApp'); }, className: "long-button", children: "WhatsApp" }), communicationTypes &&
                                    communicationTypes.map(function (type) { return (_jsx(Button, { variant: "primary", circle: true, 
                                        // disabled
                                        className: "long-button", onClick: function () { return handleTypeClick(type); }, children: type.name }, type.name)); }), _jsx(Button, { variant: "primary", circle: true, className: "long-button", onClick: function () { return handleCopy(); }, children: copy ? 'Скопировано' : 'Копировать' })] })] })), part === 2 && (_jsxs(_Fragment, { children: [_jsx("p", { className: "title", children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442 \u043A\u043E\u043C\u043C\u0443\u043D\u0438\u043A\u0430\u0446\u0438\u0438" }), _jsx("p", { className: "text" }), _jsxs("div", { className: "response-view__section ".concat(styles.comment_container), children: [_jsx("div", { className: "response-view__section-header", children: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439" }), _jsx(Form.Input, { type: "textarea", value: comment, onChange: commentChanged })] }), _jsx("div", { className: "button-contacts-wrapper", children: results &&
                                results.map(function (result) { return (_jsx(Button, { variant: "primary", circle: true, disabled: !canSubmit, className: "long-button", onClick: function () { return handleResultClick(result); }, children: result.result_name }, result.result_name)); }) })] }))] }) }));
};
export default ModalCommunication;
