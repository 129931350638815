import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { useFunnelStatuses } from '@/shared/hooks/strategy/use-funnel-statuses';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
var FunnelChart = function () {
    var items = useFunnelStatuses({}).items;
    var svgRef = useRef();
    // Функция для проверки, содержит ли значение поля name числа
    var containsNumbers = function (value) {
        return /\d/.test(value);
    };
    // Фильтрация items, чтобы исключить элементы с числами в поле name
    var filteredItems = items.filter(function (item) { return !containsNumbers(item.name); });
    useEffect(function () {
        var svg = d3.select(svgRef.current).attr('width', 300).attr('height', 350);
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };
        var width = 300;
        var height = 350;
        // const totalValue = data.reduce((acc, d) => acc + d.value, 0);
        svg.selectAll('*').remove();
        var chart = svg.append('g').attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
        // const colorScale = d3.scaleLinear()
        //     .domain([0, data.length])
        //     .range(["#11658a", "#57c3f2"]);
        var trapezoidWidth = width;
        var accumulatedHeight = 0;
        var widthReductionStep = 22;
        var currentTopWidth = trapezoidWidth;
        filteredItems.forEach(function (d, i) {
            var trapezoidHeight = height / filteredItems.length;
            var bottomWidth = Math.max(currentTopWidth - widthReductionStep, 10);
            currentTopWidth = bottomWidth;
            var x = (width - currentTopWidth) / 2;
            var y = accumulatedHeight;
            var pathData = d3.path();
            pathData.moveTo(x, y);
            pathData.lineTo(x + currentTopWidth, y);
            pathData.lineTo(x + currentTopWidth - (currentTopWidth - bottomWidth) / 2, y + trapezoidHeight);
            pathData.lineTo(x + (currentTopWidth - bottomWidth) / 2, y + trapezoidHeight);
            pathData.closePath();
            chart
                .append('path')
                .attr('d', pathData.toString())
                .attr('fill', d.color)
                .attr('stroke', '#e4edf0');
            // chart
            //   .append('text')
            //   .attr('x', x + topWidth / 2)
            //   .attr('y', y + trapezoidHeight / 2)
            //   .attr('text-anchor', 'middle')
            //   .attr('fill', 'white')
            //   .style('font-size', '12px')
            //   .text(`${d.name} : ${d.value}`)
            accumulatedHeight += trapezoidHeight;
        });
    }, [filteredItems]);
    return (_jsx("div", { className: "funnel", children: _jsx("svg", { ref: svgRef, width: 300, height: 400 }) }));
};
export default FunnelChart;
