import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Карточка контакта
import { useContactStatus } from '../hooks';
import Attachment from './attachment';
import styles from './choose-contact.module.scss';
import './style.scss';
import ContactsIconDark from '@/assets/account/images/contacts-icon-dark.png';
import ContactsIconLignt from '@/assets/account/images/contacts-icon-light.png';
import { Avatar, PageLayout, Speedometer } from '@/shared/components';
import { $$contactAvatar } from '@/shared/effector/contact/contact-avatar';
import { useMediaQuery, useSpeedometerStatuses } from '@/shared/hooks';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
export var ContactCloud = function (_a) {
    // console.log("contact в контакт клауд", contact)
    var title = _a.title, subtitle = _a.subtitle, contact = _a.contact, strategySelectSlot = _a.strategySelectSlot, buttonsSlot = _a.buttonsSlot;
    // Refs
    var _b = useUnit([
        $$contactAvatar.contactIdChanged,
        $$contactAvatar.avatarChanged,
        $$contactAvatar.$avatar,
    ]), contactIdChanged = _b[0], avatarChanged = _b[1], avatar = _b[2];
    var submited = useUnit([$$contactAvatar.submited])[0];
    var elementRef = useRef(null);
    // State
    var _c = useState(0), elementWidth = _c[0], setElementWidth = _c[1];
    // Hooks
    var _d = useContactStatus(contact.contact_id), statusPending = _d.pending, status = _d.status;
    var _e = useSpeedometerStatuses({}), speedometerPending = _e.pending, speedometer = _e.speedometer;
    var theme = useTheme().theme;
    // Variables
    var isColumn = elementWidth <= 560 && elementWidth !== 0;
    var ClassName = classNames('contact-cloud', isColumn && 'contact-cloud--column');
    var isMobile = useMediaQuery('screen and (max-width: 425px)');
    var src = theme === 'light' ? ContactsIconLignt : ContactsIconDark;
    var avatarChange = useCallback(function (e) {
        var input = e.target;
        if (input.files && input.files[0]) {
            var newFile = input.files[0];
            var formData = new FormData();
            formData.append('avatar', newFile);
            contactIdChanged(contact.contact_id);
            avatarChanged(formData);
            submited();
        }
    }, [avatarChanged, contact.contact_id, contactIdChanged, submited]);
    // Effects
    useLayoutEffect(function () {
        if (!elementRef.current) {
            return;
        }
        var handleResize = function () { var _a, _b; return setElementWidth((_b = (_a = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0); };
        handleResize();
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    if (!contact) {
        return null;
    }
    // Render
    var renderStatus = function () {
        var _a;
        if (statusPending) {
            return _jsx(Skeleton, { width: 120 });
        }
        var statusName = (_a = status === null || status === void 0 ? void 0 : status.name) !== null && _a !== void 0 ? _a : 'Не присвоен';
        return _jsx("div", { className: "contact-cloud__status", children: "\u0421\u0442\u0430\u0442\u0443\u0441: ".concat(statusName) });
    };
    var renderSpeedometer = function () {
        var content = speedometerPending ? (_jsx(Skeleton, { width: 265, height: 152, borderRadius: 32 })) : (_jsx(Speedometer, { items: speedometer, activeId: (status === null || status === void 0 ? void 0 : status.custom_strategy_status_id) || null }));
        return _jsx("div", { className: "contact-cloud__speed-test", children: content });
    };
    return (_jsx("div", { className: styles.mainContainer, children: _jsxs(PageLayout.Cloud, { elementRef: elementRef, contentClassName: ClassName, header: {
                title: title,
                subtitle: subtitle,
                right: (_jsx("div", { className: styles.contactsButtonContainer, children: _jsx("button", { className: styles.contactsButton, children: _jsx("div", { className: styles.contactsButtonDiv, children: _jsx("img", { src: src, alt: "contacts-icon" }) }) }) })),
            }, children: [_jsxs("div", { className: "contact-cloud__row", children: [_jsxs("div", { className: "contact-cloud__content", children: [_jsxs("div", { className: "contact-cloud__avatar", children: [contact.avatar ? (_jsx("img", { alt: contact.first_name, src: "https://backmoon.prm4all.com/".concat(contact.avatar), width: 100, height: 100, style: {
                                                borderRadius: "50%"
                                            } })) : (_jsx(Avatar, { size: 100 })), _jsx(Attachment, { type: "file", accept: ".png, .jpg", onChange: avatarChange })] }), _jsxs("div", { className: "contact-cloud__info", children: [_jsxs("div", { className: "contact-cloud__name", children: [contact.first_name, " ", contact.last_name] }), strategySelectSlot && !isMobile && (_jsx("div", { className: "contact-cloud__strategy", children: strategySelectSlot })), renderStatus()] })] }), renderSpeedometer()] }), buttonsSlot && (_jsx("div", { className: "contact-cloud__row", children: _jsx("div", { className: "contact-cloud__buttons", children: buttonsSlot }) }))] }) }));
};
