var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { MetamaskHeaderIcon } from '@/shared/assets';
import { $$alerts } from '@/shared/effector';
import { $$metamask } from '@/shared/effector/marketplace/metamask/metamask';
import { useUnit } from 'effector-react';
import React, { useCallback, useEffect } from 'react';
var Metamask = function () {
    var _a = useUnit([$$alerts.showSuccess, $$alerts.showDanger]), showSuccess = _a[0], showAllert = _a[1];
    var ethereum = window.ethereum;
    var _b = useUnit([
        $$metamask.$currentAccount,
        $$metamask.currentAccountChanged,
    ]), currentAddress = _b[0], setCurrentAddress = _b[1];
    // console.log("currentAddress", currentAddress)
    // const updateAccount = useCallback(async () => {
    //   if (window.ethereum) {
    //     try {
    //       const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    //       if (accounts.length > 0) {
    //         setCurrentAddress(accounts[0]);
    //       } else {
    //         setCurrentAddress(null);
    //       }
    //     } catch (error) {
    //       console.error('Failed to get accounts', error?.message);
    //     }
    //   } else {
    //     console.log('MetaMask is not installed');
    //     showAllert('MetaMask is not installed')
    //   }
    // }, []);
    var handleAccountsChanged = useCallback(function (accounts) {
        if (accounts.length > 0) {
            setCurrentAddress(accounts[0]);
            // showSuccess(`MetaMask: подключен адрес ${accounts[0]}`)
        }
        else {
            setCurrentAddress(null);
        }
    }, []);
    useEffect(function () {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
        }
        return function () {
            if (window.ethereum) {
                window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
            }
        };
    }, [handleAccountsChanged]);
    var handleMetamaskClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accounts, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.ethereum) {
                        showAllert('MetaMask is not installed');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ethereum.request({ method: 'eth_requestAccounts' })];
                case 2:
                    accounts = _a.sent();
                    setCurrentAddress(accounts[0]);
                    showSuccess("MetaMask: \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D \u0430\u0434\u0440\u0435\u0441 ".concat(accounts[0]));
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    showAllert('Metamask: ' + (error_1 === null || error_1 === void 0 ? void 0 : error_1.message));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { onClick: handleMetamaskClick, className: "header__icon header-icon header-icon--metamask ".concat(currentAddress ? 'header-icon--connected' : ''), children: _jsx("img", { src: MetamaskHeaderIcon }) }));
};
export default Metamask;
